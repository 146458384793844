import styled, { css } from 'styled-components';

import { theme } from '../../../providers/StyleProvider/theme';
import { ScoreVariant } from '../../types';

const VARIANT_TO_COLOR: Record<ScoreVariant, string> = {
	neutral: theme.colors.gray_90,
	danger: theme.colors.orange_ultraLight,
	success: theme.colors.green_ultraLight,
};

export const ScoreInterpretationContainer = styled.div<{
	$variant: ScoreVariant;
}>(
	({ theme, $variant }) => css`
		display: flex;
		padding: 12px;
		flex-direction: column;
		justify-content: center;
		gap: ${theme.spacing.xs};
		border-radius: 0 ${theme.spacing.sm} ${theme.spacing.sm} 0;
		background: ${VARIANT_TO_COLOR[$variant]};
		height: 104px;
		width: fit-content;
	`
);
