import styled, { css } from 'styled-components';

import { Caption, H4, P2 } from 'components/shared/designSystem';

export const Container = styled.div(
	({ theme: { spacing } }) => css`
		margin-bottom: ${spacing.sm};
	`
);

export const Header = styled(H4)(
	({ theme: { spacing, weight } }) => css`
		font-weight: ${weight.medium};
		margin-left: ${spacing.lg};
		margin-bottom: 12px;
	`
);

export const Section = styled.section(
	({ theme: { spacing, breakpoints } }) => css`
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: ${spacing.xl};
		justify-content: space-between;
		margin-bottom: ${spacing.lg};

		${breakpoints.tabletDown} {
			flex-direction: column;
			width: 40%;
		}
		${breakpoints.mobile} {
			width: 30%;
		}
	`
);

export const BreakdownTitle = styled(P2)(
	({ theme }) => css`
		color: ${theme.color.bodyTextSecondary};
		font-weight: ${theme.weight.medium};
	`
);

export const TrialsContainer = styled.div(
	({ theme: { spacing } }) =>
		css`
			display: flex;
			flex-direction: column;
			margin-left: ${spacing.md};
			gap: ${spacing.sm};
			width: 100%;
		`
);

export const TrialsDivider = styled.div(
	({ theme: { color } }) => css`
		background-color: ${color.infoSeparator};
		height: 1px;
	`
);

export const CaptionFaded = styled(Caption)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);
