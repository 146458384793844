import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';
import { useMemo } from 'react';
import { S3Segment, useFetchS3Segments } from 'utils/fetchS3Segments';
import { DelayedResult } from './DelayedRecall.types';

export const MAX_SCORE = '6';

function processPrompt(prompt: string) {
	return prompt
		.split(',')
		.map((word) => word[0].toUpperCase() + word.substring(1))
		.join(', ');
}

export const useDelayedRecallData = (segmentResult: DeepSegmentResult) => {
	const { delayedResult, requiresReview } = useMemo(() => {
		return parseDelayedRecallMetrics(segmentResult);
	}, [segmentResult.metricItems.length]);

	const filesToFetch = useMemo(() => {
		return buildFilesToFetch(segmentResult.id);
	}, [segmentResult.id]);

	const audioFiles = useFetchS3Segments({
		segments: filesToFetch,
		blobType: 'audio/mp4',
	});

	audioFiles.forEach((file) => {
		if (file.type === DelayedRecallFilenames.DelayedRecall) {
			delayedResult.trial.url = file.responseUrl;
		}
	});
	return { delayedResult, requiresReview };
};

export const parseDelayedRecallMetrics = (segmentResult: DeepSegmentResult) => {
	const delayedResult: DelayedResult = {
		correctResponses: '',
		trial: {
			prompt: '',
			url: '',
		},
	};

	let trialScore = 0;

	segmentResult.metricItems.forEach((metricItem) => {
		if (metricItem.key.includes('pvlt_recall_score')) {
			trialScore = Number(metricItem.value);
		}
		if (metricItem.key.includes('pvlt_expected_words')) {
			delayedResult.trial.prompt = processPrompt(metricItem.value);
		}
	});

	const requiresReview = isNaN(trialScore);
	delayedResult.correctResponses = requiresReview
		? `--/${MAX_SCORE}`
		: `${trialScore}/${MAX_SCORE}`;

	return { delayedResult, requiresReview };
};

export enum DelayedRecallFilenames {
	DelayedRecall = 'delayed-recall',
}

export const buildFilesToFetch = (segmentResultId: string): S3Segment[] => {
	return [
		{
			segmentResultId,
			type: DelayedRecallFilenames.DelayedRecall,
			filename: 'delayed-recall-audio.mp4',
		},
	];
};
