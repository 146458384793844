import { MetricItem } from '@lh/eng-platform-battery-service-rest-client';

import { reverseString } from 'utils/stringUtils';

import { BdstPerformance, BreakdownScores } from './BdstReport.types';

const MAX_MATCH_SCORE = 5;
const MAX_ANY_SCORE = 5;
const MAX_TOTAL_SCORE = 15;

export function parseBDSTMetrics(metricItems: MetricItem[]) {
	const breakdownScores: BreakdownScores = {
		trialOne: {
			audio: {},
			score: {},
		},
		trialTwo: {
			audio: {},
			score: {},
		},
		trialThree: {
			audio: {},
			score: {},
		},
	};
	const performance: BdstPerformance = {
		impaired: -1,
		quartile: null,
		score: null,
	};

	metricItems.forEach((metricItem) => {
		if (metricItem.key.includes('trial_1')) {
			if (metricItem.key.includes('expected_digits')) {
				breakdownScores.trialOne.audio.prompt = reverseString(
					metricItem.value
				);
			}

			if (metricItem.key.includes('transcribed_digits')) {
				breakdownScores.trialOne.audio.backwards = metricItem.value;
			}

			if (metricItem.key.includes('match_score')) {
				breakdownScores.trialOne.score.any = `${metricItem.value}/${MAX_MATCH_SCORE}`;
			}

			if (metricItem.key.includes('order_score')) {
				breakdownScores.trialOne.score.serial = `${metricItem.value}/${MAX_ANY_SCORE}`;
			}
		}

		if (metricItem.key.includes('trial_2')) {
			if (metricItem.key.includes('expected_digits')) {
				breakdownScores.trialTwo.audio.prompt = reverseString(
					metricItem.value
				);
			}

			if (metricItem.key.includes('transcribed_digits')) {
				breakdownScores.trialTwo.audio.backwards = metricItem.value;
			}

			if (metricItem.key.includes('match_score')) {
				breakdownScores.trialTwo.score.any = `${metricItem.value}/${MAX_MATCH_SCORE}`;
			}

			if (metricItem.key.includes('order_score')) {
				breakdownScores.trialTwo.score.serial = `${metricItem.value}/${MAX_ANY_SCORE}`;
			}
		}

		if (metricItem.key.includes('trial_3')) {
			if (metricItem.key.includes('expected_digits')) {
				breakdownScores.trialThree.audio.prompt = reverseString(
					metricItem.value
				);
			}

			if (metricItem.key.includes('transcribed_digits')) {
				breakdownScores.trialThree.audio.backwards = metricItem.value;
			}

			if (metricItem.key.includes('match_score')) {
				breakdownScores.trialThree.score.any = `${metricItem.value}/${MAX_MATCH_SCORE}`;
			}

			if (metricItem.key.includes('order_score')) {
				breakdownScores.trialThree.score.serial = `${metricItem.value}/${MAX_ANY_SCORE}`;
			}
		}

		if (metricItem.key === 'backward_digit_span_total_ser_order_score') {
			performance.score = isNaN(Number(metricItem.value))
				? null
				: `${metricItem.value}/${MAX_TOTAL_SCORE}`;
		}

		if (
			metricItem.key ===
			'backward_digit_span_total_ser_order_score_quartile'
		) {
			// Old metric returns string, new metric returns integers
			if (
				metricItem.value !== 'NotAvailable' &&
				metricItem.value !== '-1'
			) {
				let valueNumber = Number(metricItem.value);

				if (!isNaN(valueNumber)) {
					// New engine, quartile is an integer (1, 2, 3, 4)
					performance.quartile = valueNumber;
				} else {
					// Old engine, quartile is a string (3rd quartile (50th to 74th percentile))
					const quartile = metricItem.value.at(0);
					valueNumber = Number(quartile);

					if (!isNaN(valueNumber)) {
						performance.quartile = valueNumber;
					}
				}
			}
		}

		if (metricItem.key === 'backward_digit_span_age_range') {
			performance.ageRange = metricItem.value;
		}

		if (
			metricItem.key ===
			'backward_digit_span_impairment_cutoff_ser_order_score'
		) {
			performance.cutoff = metricItem.value;
		}

		if (
			metricItem.key ===
			'backward_digit_span_impairment_classification_ser_order_score'
		) {
			if (metricItem.value === 'IMPAIRED') {
				performance.impaired = 1;
			} else {
				performance.impaired = 0;
			}
		}
	});

	return {
		breakdownScores,
		performance,
	};
}
