import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { TrialsResult } from './ImmediateRecall.types';

export const MAX_SCORE = '12';
const MAX_TRIAL_SCORE = '6';

function processPrompt(prompt: string) {
	return prompt
		.split(',')
		.map((word) => word[0].toUpperCase() + word.substring(1))
		.join(', ');
}

export function parseImmediateRecallMetrics(segmentResult: DeepSegmentResult) {
	const trialsResult: TrialsResult = {
		correctResponses: '',
		trial1: {
			score: '',
			prompt: '',
			url: '',
		},
		trial2: {
			score: '',
			prompt: '',
			url: '',
		},
	};

	let trial1NumericScore = 0;
	let trial2NumericScore = 0;

	segmentResult.metricItems.forEach((metricItem) => {
		if (metricItem.key.includes('pvlt_recall_score_trial_1')) {
			trial1NumericScore = Number(metricItem.value);
		}
		if (metricItem.key.includes('pvlt_recall_score_trial_2')) {
			trial2NumericScore = Number(metricItem.value);
		}
		if (metricItem.key.includes('pvlt_expected_words_trial_1')) {
			trialsResult.trial1.prompt = processPrompt(metricItem.value);
		}
		if (metricItem.key.includes('pvlt_expected_words_trial_2')) {
			trialsResult.trial2.prompt = processPrompt(metricItem.value);
		}
	});

	const totalScore = trial1NumericScore + trial2NumericScore;
	const requiresReview = isNaN(totalScore);
	trialsResult.correctResponses = requiresReview
		? `--/${MAX_SCORE}`
		: `${totalScore}/${MAX_SCORE}`;

	trialsResult.trial1.score = isNaN(trial1NumericScore)
		? `--`
		: `${trial1NumericScore}/${MAX_TRIAL_SCORE}`;
	trialsResult.trial2.score = isNaN(trial2NumericScore)
		? `--`
		: `${trial2NumericScore}/${MAX_TRIAL_SCORE}`;

	return { trialsResult, requiresReview };
}
