import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { AudioPlaybackCard, ReportInfoPanel } from 'components/mosaic';

import { ImmediateRecallBreakdown } from './ImmediateRecallBreakdown';
import { ImmediateRecallScore } from './ImmediateRecallScore';

import {
	CaptionFaded,
	Container,
	Header,
	Section,
} from './ImmediateRecall.style';
import { TrialsResult } from './ImmediateRecall.types';

export type ImmediateRecallLayoutProps = {
	requiresReview: boolean;
	trials: TrialsResult;
};

export function ImmediateRecallLayout({
	requiresReview,
	trials,
}: Readonly<ImmediateRecallLayoutProps>) {
	const { t } = useTranslation();
	const { correctResponses, trial1, trial2 } = trials;

	return (
		<Container>
			<Section>
				<ReportInfoPanel
					stretch
					title={t('web.report.pvltImmediateReport.immediateRecall')}
					tooltipText={
						t('web.report.pvltImmediateReport.description', {
							returnObjects: true,
						}) as string[]
					}
				>
					<Flex>
						<ImmediateRecallScore
							correctResponses={correctResponses}
							requiresReview={requiresReview}
						/>
						<ImmediateRecallBreakdown trials={trials} />
					</Flex>
				</ReportInfoPanel>
			</Section>
			<Header>{t('web.shared.recordings')}</Header>
			<Section>
				<AudioPlaybackCard
					audioSource={trial1.url}
					cardTitle={t(
						'web.report.pvltImmediateReport.immediateRecall'
					)}
				>
					{t('web.report.pvltImmediateReport.prompt')} {trial1.prompt}
				</AudioPlaybackCard>
				<AudioPlaybackCard
					audioSource={trial2.url}
					cardTitle={t(
						'web.report.pvltImmediateReport.immediateRecallRepeat'
					)}
				>
					{t('web.report.pvltImmediateReport.prompt')} {trial2.prompt}
				</AudioPlaybackCard>
			</Section>
			<CaptionFaded>{t('web.report.footer.info')}</CaptionFaded>
		</Container>
	);
}
