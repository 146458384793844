import { useTranslation } from 'react-i18next';

import { ReportCardHeader } from 'components/mosaic';
import { LineDivider } from 'components/shared/LineDivider';
import { P2 } from 'components/shared/designSystem';
import { ScoreWithInterpretation } from '../../../../mosaic/organisms';
import { renderRequiresReviewComponent } from '../../components/InterpretationComponent';

import { DelayedRecognition } from './DelayedRecognition.types';
import { DelayedRecognitionTable } from './DelayedRecognitionTable';
import {
	CaptionFaded,
	Card,
	Container,
	Wrapper,
} from './DelayedReocognitionLayout.style';

export type DelayedRecognitionLayoutProps = {
	delayedResult: DelayedRecognition;
	requiresReview: boolean;
};

export function DelayedRecognitionLayout({
	delayedResult,
	requiresReview,
}: Readonly<DelayedRecognitionLayoutProps>) {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<Container>
				<Card>
					<ReportCardHeader
						title={t('web.report.pvltDelayedRecognition.title')}
						tooltipText={t(
							'web.report.pvltDelayedRecognition.subtitle'
						)}
					/>
					<LineDivider />
					<div
						style={{
							minWidth: '445px',
							width: '445px',
						}}
					>
						<ScoreWithInterpretation
							score={{
								headerText: t(
									'web.report.pvltDelayedRecognition.correctResponsesTitle'
								),
								value: delayedResult.correctResponses,
							}}
							renderInterpretationText={() => (
								<P2>
									{renderRequiresReviewComponent({
										requiresReview: requiresReview,
									})}
								</P2>
							)}
							// Tentatively hiding extra details but we should still show 'requires review' content
							hideTextPanel={!requiresReview}
						/>
					</div>
				</Card>
			</Container>
			<Container>
				<DelayedRecognitionTable answers={delayedResult.answers} />
			</Container>
			<CaptionFaded>{t('web.report.footer.info')}</CaptionFaded>
		</Wrapper>
	);
}
