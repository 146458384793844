import { t } from 'i18next';

import { theme } from 'components/providers/StyleProvider/theme';

const ficConditions: Record<
	string,
	{ scoreBgColor: string; text: string; textColor: string }
> = {
	'<0': {
		scoreBgColor: theme.colors.gray_80,
		text: t('web.report.fic.score.grey'),
		textColor: theme.color.graphTextPrimary,
	},
	'0-22': {
		scoreBgColor: theme.colors.green_lightest,
		text: t('web.report.fic.score.detected'),
		textColor: theme.colors.green_darkest,
	},
	'23-35': {
		scoreBgColor: theme.colors.yellow_lightest,
		text: t('web.report.fic.score.forImpairment'),
		textColor: theme.colors.yellow_darkest,
	},
	'36-100': {
		scoreBgColor: theme.colors.orange_lightest,
		text: t('web.report.fic.score.forImpairment'),
		textColor: theme.colors.orange_darkest,
	},
};

export const getFicConainerType = (score: number) => {
	if (score < 0 || isNaN(score)) return ficConditions['<0'];
	if (score <= 22) return ficConditions['0-22'];
	if (score <= 35) return ficConditions['23-35'];

	return ficConditions['36-100'];
};

export function getFicAdditionalText(score: number) {
	if (score < 0 || isNaN(score)) {
		return '--';
	}

	if (score <= 22) {
		return t('web.report.fic.score.green');
	}

	if (score <= 35) {
		return t('web.report.fic.score.yellow');
	}

	return t('web.report.fic.score.red');
}
