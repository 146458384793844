import { Stack } from '@mantine/core';
import React from 'react';

import { theme } from '../../../providers/StyleProvider/theme';
import { AudioWidget } from '../../../shared/AudioWidget';

import { Card, Content, Header } from './AudioPlaybackCard.style';

type AudioPlaybackCardProps = {
	audioSource: string;
	children: React.ReactNode;
	cardTitle: string;
};

export function AudioPlaybackCard({
	audioSource,
	cardTitle,
	children,
}: Readonly<AudioPlaybackCardProps>) {
	return (
		<Card>
			<Stack gap={theme.spacing.xxs}>
				<Header data-testid='AudioPlaybackCard_title'>
					{cardTitle}
				</Header>
				<Content data-testid='AudioPlaybackCard_prompt'>
					{children}
				</Content>
			</Stack>
			<AudioWidget data-testid='audioWidget' srcUrl={audioSource} />
		</Card>
	);
}
