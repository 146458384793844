import { TabbedLayout } from 'components/layout/TabbedLayout';
import { BatteryHeaderFragment, BatteryType } from '../../generated/graphql';

import { MiniModalReport } from './AssessmentComponents/MiniModal/MiniModalReport';
import { BdstReport } from './BDST';
import { Report } from './CognitiveEvaluation.types';
import { CognitiveEvaluationTabbedLayout } from './CognitiveEvaluationTabbedLayout';
import { generateDynamicTabs } from './CognitiveEvaluationTabbedLayout/cognitiveEvaluationAccessibleTabs';
import { FastReport } from './FAST/FastReport';
import { Gad7Report } from './Gad7';
import { GdsReport } from './GeriatricDepressionScale';
import { HearingScreenerReportLayout } from './HearingScreener/HearingScreenerReport';
import { OrientationLayout } from './OrientationQuestionaire';
import { LifeAndHealthReport } from './SegmentComponents/LifeAndHealth/LifeAndHealthReport';
import { TrailsLayout } from './TrailsLayout';
import { PhonemicLayout } from './custom/Fluency/Phonemic';
import { SemanticReport } from './custom/Fluency/Semantic/SemanticReport';
import { IadlLayout } from './custom/iadl/IadlLayout/IadlLayout';
import { Lhq15 } from './LHQ15';

interface CognitiveEvaluationBodyProps {
	readonly batteryParticipant?: BatteryHeaderFragment;
	readonly batteryResult: Report;
	readonly batteryType: BatteryType;
	readonly startPolling: () => void;
	readonly infoContainerRef: React.RefObject<HTMLDivElement>;
	readonly isContentLoading: boolean;
	readonly setIsContentLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function CognitiveEvaluationBody({
	batteryResult,
	startPolling,
	infoContainerRef,
	isContentLoading,
	setIsContentLoading,
	batteryParticipant,
	batteryType,
}: CognitiveEvaluationBodyProps) {
	switch (batteryType) {
		case BatteryType.CognitiveEvaluation:
		case BatteryType.Default:
		case BatteryType.CcePlus:
			return (
				<CognitiveEvaluationTabbedLayout
					batteryParticipant={batteryParticipant}
					batteryResult={batteryResult}
					patientReportHandler={startPolling}
					previousComponentRef={infoContainerRef}
					isContentLoading={isContentLoading}
					setIsContentLoading={setIsContentLoading}
				/>
			);

		case BatteryType.Dcr:
		case BatteryType.DcrPlus:
			return <MiniModalReport batteryResult={batteryResult} />;

		case BatteryType.Gad7:
			return <Gad7Report data={batteryResult} />;

		case BatteryType.Gds:
			return <GdsReport batteryResult={batteryResult} />;

		case BatteryType.Semantic:
			return <SemanticReport batteryResult={batteryResult} />;

		case BatteryType.Fast:
			return <FastReport data={batteryResult} />;

		case BatteryType.HearingScreener:
			return (
				<HearingScreenerReportLayout baterryResult={batteryResult} />
			);

		case BatteryType.Phonemic:
			return <PhonemicLayout batteryResult={batteryResult} />;

		case BatteryType.Bds:
			return <BdstReport batteryResult={batteryResult} />;

		case BatteryType.Iadl:
			return <IadlLayout iadlQuestionnaireData={batteryResult} />;

		case BatteryType.Adl:
			return (
				<IadlLayout type='adl' iadlQuestionnaireData={batteryResult} />
			);

		case BatteryType.Lhq_15:
			return (
				<Lhq15
					batteryResult={batteryResult}
					offsetComponentRef={infoContainerRef}
					isLoading={isContentLoading}
				/>
			);

		case BatteryType.Lhq:
			return (
				<LifeAndHealthReport
					batteryResult={batteryResult}
					isLoading={isContentLoading}
					offsetComponentRef={infoContainerRef}
				/>
			);

		case BatteryType.Orientation:
			return (
				<OrientationLayout
					orientationQuestionnaireData={batteryResult}
				/>
			);

		case BatteryType.Trails:
			return <TrailsLayout data={batteryResult} />;

		default: {
			const tabs = generateDynamicTabs(batteryResult);
			return <TabbedLayout tabs={tabs} />;
		}
	}
}
