import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useEffect, useMemo, useState } from 'react';

import { S3Segment, useFetchS3Segments } from 'utils/fetchS3Segments';

import { parseBDSTMetrics } from './parseBDSTMetrics';

enum BdstFileNames {
	Trial1 = 'trial1',
	Trial2 = 'trial2',
	Trial3 = 'trial3',
}

const buildFilesToFetch = (segmentResultId: string): S3Segment[] => {
	return [
		{
			segmentResultId,
			type: BdstFileNames.Trial1,
			filename: 'backwards_digit_span_audio_trial1.mp4',
		},
		{
			segmentResultId,
			type: BdstFileNames.Trial2,
			filename: 'backwards_digit_span_audio_trial2.mp4',
		},
		{
			segmentResultId,
			type: BdstFileNames.Trial3,
			filename: 'backwards_digit_span_audio_trial3.mp4',
		},
	];
};

export function useBDSTData(segmentResult: DeepSegmentResult) {
	const { breakdownScores, performance } = useMemo(() => {
		return parseBDSTMetrics(segmentResult.metricItems);
	}, [segmentResult.metricItems.length]);

	const filesToFetch = useMemo(() => {
		return buildFilesToFetch(segmentResult.id);
	}, [segmentResult.id]);

	const [trialRecording, setTrialRecording] = useState({
		trialOne: '',
		trialTwo: '',
		trialThree: '',
	});

	const audioFiles = useFetchS3Segments({
		segments: filesToFetch,
		blobType: 'audio/mp4',
	});

	useEffect(() => {
		audioFiles.forEach((file) => {
			if (file.type === BdstFileNames.Trial1) {
				setTrialRecording({
					...trialRecording,
					trialOne: file.responseUrl,
				});
			}
			if (file.type === BdstFileNames.Trial2) {
				setTrialRecording({
					...trialRecording,
					trialTwo: file.responseUrl,
				});
			}
			if (file.type === BdstFileNames.Trial3) {
				setTrialRecording({
					...trialRecording,
					trialThree: file.responseUrl,
				});
			}
		});
		// eslint disable next line react-hooks/exhaustive-deps
	}, [audioFiles]);

	return {
		breakdownScores,
		performance,
		recordings: {
			trialOne: trialRecording.trialOne,
			trialTwo: trialRecording.trialTwo,
			trialThree: trialRecording.trialThree,
		},
	};
}
