import { css, styled } from 'styled-components';
import { StyledCard } from 'components/shared/StyledCard';

export const Stack = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

export const Card = styled(StyledCard)<{ $stretch: boolean }>(
	({ $stretch, theme: { spacing } }) => css`
		padding: ${spacing.lg};
		width: ${$stretch ? '100%' : 'auto'};
	`
);
