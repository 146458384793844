import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { MMSEAccordion } from 'components/report/BatteryComponents/MMSE';
import { Impairment } from 'components/report/BatteryComponents/Impairment/Impairment';
import { LineDivider } from 'components/shared/LineDivider';
import { P2 } from 'components/shared/designSystem';
import { useOrganizationFeatures } from 'components/shared/hooks/useOrganizationFeatures';
import { FeatureType } from 'generated/graphql';

interface AdditionalDcrResultsProps {
	mmsePrediction?: number;
	ficScore?: number;
	mipScore?: number;
}

export function AdditionalDcrResults({
	mmsePrediction,
	ficScore,
	mipScore,
}: Readonly<AdditionalDcrResultsProps>) {
	const features = useOrganizationFeatures();
	const { t } = useTranslation();

	const showMipSection =
		features.includes(FeatureType.MemoryImpairmentProbability) &&
		mipScore !== undefined &&
		!isNaN(mipScore);

	const showMmmseSection =
		features.includes(FeatureType.MmseEstimation) &&
		mmsePrediction !== undefined &&
		!isNaN(mmsePrediction);

	const showFunctionalImpairmentConcern =
		features.includes(FeatureType.FunctionalImpairmentProbability) &&
		ficScore !== undefined &&
		!isNaN(ficScore);

	return (
		<Container>
			<DCRResulsTitle>
				{t('web.report.mip.additionalDcrResults').toUpperCase()}
			</DCRResulsTitle>
			{showMipSection && (
				<>
					<Spacer>
						<Impairment score={mipScore} />
					</Spacer>
					<Spacer>
						<LineDivider />
					</Spacer>
				</>
			)}
			{showFunctionalImpairmentConcern && (
				<>
					<Spacer>
						<Impairment type='fic' score={ficScore} />
					</Spacer>
					<Spacer>
						<LineDivider />
					</Spacer>
				</>
			)}
			{showMmmseSection && (
				<MMSEAccordion marginOfError={3} score={mmsePrediction} />
			)}
		</Container>
	);
}

const Spacer = styled.div(
	({ theme: { spacing } }) => css`
		margin-top: ${spacing.sm};
	`
);

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const DCRResulsTitle = styled(P2)(
	({ theme: { color, weight } }) => css`
		font-weight: ${weight.medium};
		color: ${color.bodyTextSecondary};
	`
);
